import Head from 'next/head';
import Footer from './Footer';
import styles from './Layout.module.css';
import Nav from './Nav';
import { useSelector } from 'react-redux';
import { selectTheme, selectUser } from 'store/appSlice';

const Layout = ({ children }) => {
  const user = useSelector(selectUser);
  const appTheme = useSelector(selectTheme);

  return (
    <>
      <Head>
        <title>Yours Tradely</title>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="nextjs-mongodb-app is a continously developed app built with Next.JS and MongoDB. This project goes further and attempts to integrate top features as seen in real-life apps."
        />
        <meta property="og:title" content="Next.js + MongoDB App" />
        <meta
          property="og:description"
          content="nextjs-mongodb-app is a continously developed app built with Next.JS and MongoDB. This project goes further and attempts to integrate top features as seen in real-life apps."
        />
        <meta
          property="og:image"
          content="https://repository-images.githubusercontent.com/201392697/5d392300-eef3-11e9-8e20-53310193fbfd"
        />
      </Head>
      <div id="application" data-theme={appTheme}>
        <div id="layout" className={styles.layout}>
          <Nav loggedIn={user.isValid} />
          <main className={user.isValid && styles.main}>{children}</main>
          {user.isValid && <Footer />}
        </div>
      </div>
    </>
  );
};

export default Layout;
