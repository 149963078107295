import {
  ListItemButton as MUIListItemButton,
  ListItemIcon as MUIListItemIcon,
  ListItemText as MUIListItemText,
} from '@mui/material';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styles from './ListItemButton.module.css';

const ListItemButton = ({
  showText = false,
  label = '',
  description = '',
  onClick = noop,
  Icon = () => {},
  selected = false,
}) => {
  return (
    <MUIListItemButton
      sx={{
        minHeight: 48,
        justifyContent: showText ? 'initial' : 'center',
        px: 2.5,
      }}
      className={`${styles.item} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      <MUIListItemIcon
        sx={{
          minWidth: 0,
          mr: showText ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        <Icon className={styles.icon} />
      </MUIListItemIcon>
      {(label || description) && (
        <MUIListItemText
          sx={{
            m: 0,
            p: 1,
            position: 'absolute',
            left: '99%',
            top: 0,
            backgroundColor: 'var(--hover-bg)',
            width: 400,
          }}
          classes={{
            primary: styles.textPrimary,
            secondary: styles.textSecondary,
            root: styles.textRoot,
          }}
          primary={label}
          secondary={description}
        />
      )}
    </MUIListItemButton>
  );
};

ListItemButton.propTypes = {
  showText: PropTypes.bool,
  //   size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default ListItemButton;
