export const NY_TZ = 'America/New_York';
export const TIME = {
  MINUTES: 'min',
  DATE: 'date',
  YEAR: 'year',
};
export const CONFIG_TYPES = {
  NEXT_DAY: 'loadNextDay',
  PREVIOUS_DAY: 'loadPreviousDay',
  SELECTED_DAY: 'selectedDay',
  TIMEFRAME: 'timeframe',
};

export const MENU_TYPES = {
  MENU_LIST: 'menu-list',
  DIVIDER: 'divider',
};

export const MENU_CATEGORIES = {
  CUSTOM_PAGES: 'custom-pages',
};

export const WIDGET_TYPES = {
  GAINERS: 'gainers',
  HALTS: 'halts',
  CHART: 'chart',
  STOCK_SEARCH: 'stock-search',
  FUNDAMENTALS: 'fundamentals',
  WATCHLIST: 'watchlist',
  HISTORY: 'history',
};

export const TIMEFRAMES = {
  MINS_1: '1Min',
  MINS_2: '2Min',
  MINS_5: '5Min',
  MINS_15: '15Min',
  HOURS_1: '1H',
  DAYS_1: '1D',
};

export const TIMEFRAME_OPTIONS = [
  {
    name: '1m',
    value: TIMEFRAMES.MINS_1,
  },
  {
    name: '2m',
    value: TIMEFRAMES.MINS_2,
  },
  {
    name: '5m',
    value: TIMEFRAMES.MINS_5,
  },
  {
    name: '15m',
    value: TIMEFRAMES.MINS_15,
  },
  {
    name: '1h',
    value: TIMEFRAMES.HOURS_1,
  },
  {
    name: '1D',
    value: TIMEFRAMES.DAYS_1,
  },
];

export const SUBSCRIPTION_TYPES = {
  TRADES: 'trades',
  QUOTES: 'quotes',
  BARS: 'bars',
};

export const MESSAGE_TYPES = {
  MOMENTUM: 'MOMENTUM',
  DATACLIENT_WS_CONNECTED: 'DATACLIENT_WS_CONNECTED',
};

export const CHART_COLORS = [
  '#3498db', // Cerulean Blue
  '#e74c3c', // Sunset Orange
  '#2ecc71', // Emerald Green
  '#9b59b6', // Amethyst Purple
  '#f1c40f', // Sunflower Yellow
  '#34495e', // Midnight Blue
  '#e67e22', // Carrot Orange
  '#e74c3c', // Alizarin Red
  '#1abc9c', // Turquoise
  '#8e44ad',
];

export const REGEX = {
  WIDGET_ID: /_id_([a-zA-Z0-9/-]+)_id_/,
};

export const ACTIONS = {
  DELETE: 'delete',
};

export const CELL_TYPES = {
  ACTION: 'action',
  REACT: 'React',
};

export const COLORS = {
  BACKGROUND_GREEN: 'green',
  BACKGROUND_RED: 'red',
};

const DILUTION = 'dilution';
export const ALL = 'all';
const FINANCIALS = 'financials';
const DISCLOSURES = 'disclosures';
const OWNERSHIP = 'ownership';
export const OTHERS = 'others';

export const PROMPTS = {
  DILUTION: `You are an extremely knowledgeable and experienced Chartered Accountant who specializes in reading SEC filings. Never provide examples when information is not available. Dont halluciante.
            You specialize in looking for any potential dilution via At the market(ATM) offerings, Warrants etc.
            Also provide information about shares available for dilution, warrant prices, atm price, offereing price, outstanding shares, banking partners etc.
            When available summarize financial position of the company mainly around current cash reserves and burn rate.
            Input provided to you can be in html, xml, txt or other formats. Process the input as necessary before providing summary.
            Ignore summarizing about the input provided and dive right into helpful information.
            Please use this formula to calcualte Float(=Total Outstanding Shares−(Insider Holdings+Institutional Holdings+Restricted Shares)) of the company where applicable and display it at the top.
            In addition to regular response, summarize information about shares in a table for users to easily capture and process the information and place it below Float information. Use react markdown for responses`,
  GENERAL: `You are an extremely knowledgeable and experienced Chartered Accountant who specializes in reading SEC filings. Based on the standard defintion for SEC {filingType}, Summarize the following.`,
};

export const FILING_TYPES = {
  // DILUTION
  S1: {
    form: 'S-1',
    description:
      'Registration statement for securities offering by U.S. companies.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F1: {
    form: 'F-1',
    description:
      'Registration statement for securities offering by foreign private issuers.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  S1_A: {
    form: 'S-1/A',
    description: 'Amendment to Form S-1 registration statement.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F1_A: {
    form: 'F-1/A',
    description: 'Amendment to Form F-1 registration statement.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  EFFECT: {
    form: 'EFFECT',
    description:
      'Notice of effectiveness, signaling a registration statement is effective.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  S1MEF: {
    form: 'S-1MEF',
    description:
      'Automatic shelf registration for additional securities under an existing S-1 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F1MEF: {
    form: 'F-1MEF',
    description:
      'Automatic shelf registration for additional securities under an existing F-1 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  TYPE_424B4: {
    form: '424B4',
    description:
      'Prospectus filed to update a registration statement after an initial public offering.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  S3: {
    form: 'S-3',
    description:
      'Short-form registration for securities by qualifying U.S. companies.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F3: {
    form: 'F-3',
    description:
      'Short-form registration for securities by foreign private issuers.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F10: {
    form: 'F-10',
    description:
      'Registration statement for Canadian issuers under the Multijurisdictional Disclosure System (MJDS).',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  S3MEF: {
    form: 'S-3MEF',
    description:
      'Automatic shelf registration for additional securities under an existing S-3 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F3MEF: {
    form: 'F-3MEF',
    description:
      'Automatic shelf registration for additional securities under an existing F-3 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  S3ASR: {
    form: 'S-3ASR',
    description:
      'Automatic shelf registration for seasoned issuers filing an S-3 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  F3ASR: {
    form: 'F-3ASR',
    description:
      'Automatic shelf registration for seasoned foreign private issuers filing an F-3 registration.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  TYPE_424B5: {
    form: '424B5',
    description:
      'Prospectus with updates to a previously filed registration statement.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  TYPE_424B3: {
    form: '424B3',
    description:
      'Prospectus filed under Rule 424(b)(3), commonly used for updated pricing information.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },
  RW: {
    form: 'RW',
    description: 'Application to withdraw a registration statement.',
    tags: ['dilution'],
    prompt: PROMPTS.DILUTION,
  },

  // FINANCIALS
  TYPE_10Q: {
    form: '10-Q',
    description: 'Quarterly financial report filed by U.S. companies.',
    tags: ['financials'],
  },
  TYPE_10K: {
    form: '10-K',
    description: 'Annual financial report filed by U.S. companies.',
    tags: ['financials'],
  },
  TYPE_20F: {
    form: '20-F',
    description: 'Annual report for foreign private issuers.',
    tags: ['financials'],
  },
  TYPE_40F: {
    form: '40-F',
    description: 'Annual report for Canadian issuers under MJDS.',
    tags: ['financials'],
  },

  // DISCLOSURES
  TYPE_8K: {
    form: '8-K',
    description: 'Report of unscheduled material events or corporate changes.',
    tags: ['disclosures'],
  },
  TYPE_6K: {
    form: '6-K',
    description:
      'Current report for foreign issuers disclosing material events.',
    tags: ['disclosures'],
  },

  // OWNERSHIP
  FORM_3: {
    form: '3',
    description: 'Initial statement of beneficial ownership of securities.',
    tags: ['ownership'],
  },
  FORM_4: {
    form: '4',
    description: 'Statement of changes in beneficial ownership of securities.',
    tags: ['ownership'],
  },
  FORM_5: {
    form: '5',
    description:
      'Annual statement of changes in beneficial ownership of securities.',
    tags: ['ownership'],
  },
  SC_13D: {
    form: 'SC 13D',
    description:
      'Statement filed by beneficial owners acquiring more than 5% of a class of equity securities.',
    tags: ['ownership'],
  },
  SC_13G: {
    form: 'SC 13G',
    description:
      'Short-form statement for passive beneficial owners acquiring more than 5% of a class of equity securities.',
    tags: ['ownership'],
  },
  SC_13D_A: {
    form: 'SC 13D/A',
    description: 'Amendment to Schedule 13D.',
    tags: ['ownership'],
  },
  SC_13G_A: {
    form: 'SC 13G/A',
    description: 'Amendment to Schedule 13G.',
    tags: ['ownership'],
  },
};

export const FILINGS_CATEGORIES = [
  {
    name: ALL,
    value: ALL,
  },
  {
    name: DILUTION,
    value: DILUTION,
    types: [
      FILING_TYPES.S1,
      FILING_TYPES.F1,
      FILING_TYPES.S1_A,
      FILING_TYPES.F1_A,
      FILING_TYPES.EFFECT,
      FILING_TYPES.S1MEF,
      FILING_TYPES.F1MEF,
      FILING_TYPES.TYPE_424B4,
      FILING_TYPES.S3,
      FILING_TYPES.F3,
      FILING_TYPES.F10,
      FILING_TYPES.S3MEF,
      FILING_TYPES.F3MEF,
      FILING_TYPES.S3ASR,
      FILING_TYPES.F3ASR,
      FILING_TYPES.TYPE_424B5,
      FILING_TYPES.TYPE_424B3,
      FILING_TYPES.RW,
    ],
  },
  {
    name: FINANCIALS,
    value: FINANCIALS,
    types: [
      FILING_TYPES.TYPE_10Q,
      FILING_TYPES.TYPE_10K,
      FILING_TYPES.TYPE_20F,
      FILING_TYPES.TYPE_40F,
    ],
  },
  {
    name: DISCLOSURES,
    value: DISCLOSURES,
    types: [FILING_TYPES.TYPE_8K, FILING_TYPES.TYPE_6K],
  },
  {
    name: OWNERSHIP,
    value: OWNERSHIP,
    types: [
      FILING_TYPES.FORM_3,
      FILING_TYPES.FORM_4,
      FILING_TYPES.FORM_5,
      FILING_TYPES.SC_13D,
      FILING_TYPES.SC_13G,
      FILING_TYPES.SC_13D_A,
      FILING_TYPES.SC_13G_A,
    ],
  },
  {
    name: OTHERS,
    value: OTHERS,
    types: [],
  },
];

export const getConstructedPrompt = (formType) => {
  const formDefs = Object.values(FILING_TYPES);
  const formDefsHash = formDefs.reduce((acc, curr) => {
    acc[curr.form] = curr;
    return acc;
  }, {});

  const _def = formDefsHash[formType.toUpperCase()];
  const _generalPrompt = PROMPTS.GENERAL.replace('{filingType}', formType);

  if (_def) {
    return _def.prompt || _generalPrompt;
  }

  return _generalPrompt;
};

export const CONTEXT_MENU_IDS = {
  ADD_TO_WATCHLIST: 'add-to-watchlist',
};

export const TABLE_CONTEXT_MENU = {
  ADD_TO_WATCHLIST: {
    label: 'Add to watchlist',
    id: CONTEXT_MENU_IDS.ADD_TO_WATCHLIST,
  },
};
